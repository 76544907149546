<template>
	<div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col mb-3">
                     <label><i class="far fa-search color-theme font-11 me-1"></i> Pesquisar</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus @keyup.enter="buscarPostagens(0)" placeholder="Busque por título da notícia" />
                  </div>
                  <div class="col-12 col-sm-5 col-md-3 col-xl-2 px-0 mb-3 align-self-end">
                     <div class="row">
                        <div class="col">
                           <button type="button" class="btn btn-primary w-100" @click="buscarPostagens(0)"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8">
                           <button type="button" class="btn btn-primary w-100" @click="abrirPostagem(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="cards-150 cards-sm-250">
            <noticia v-for="(noticia, index) in pesquisa.retorno.resultado" :key="index" :index="index" :noticia="noticia" @editar="abrirPostagem($event)" />
         </div>
      </div>

      <div class="col-12 px-1 mt-3 mb-2 text-center" v-if="pesquisa.retorno.resultado.length > 0 && pesquisa.retorno.resultado.length % 50 == 0 && (pesquisa.retorno.valor == null || String(pesquisa.retorno.valor).trim().length == 0)">
         <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarPostagens(null)">Ver mais</button>
      </div>

      <!-- modalPostagem -->
      <div class="modal fade" id="modalPostagem" aria-labelledby="modalPostagemLabel" data-bs-backdrop="static" aria-hidden="true">
         <div class="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPostagemLabel" v-if="postagem.id != null">Editar postagem</h5>
                  <h5 class="modal-title" id="modalPostagemLabel" v-else>Adicionar postagem</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2">
                     <div class="card-body p-12">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                           <li class="nav-item col" role="presentation">
                              <button class="nav-link m-0 active" id="pills-basico-tab" data-bs-toggle="pill" data-bs-target="#pills-basico" type="button" role="tab" aria-controls="pills-basico" aria-selected="true">
                                 <i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Info. básica</span>
                              </button>
                           </li>
                           <li class="nav-item col" role="presentation">
                              <button class="nav-link m-0" id="pills-corpo-tab" data-bs-toggle="pill" data-bs-target="#pills-corpo" type="button" role="tab" aria-controls="pills-corpo" aria-selected="false">
                                 <i class="far fa-align-justify font-13"></i><span class="ms-1 d-none d-sm-inline"> Corpo da notícia</span>
                              </button>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div class="tab-content" id="pills-tabContent">
                     <div class="tab-pane fade show active" id="pills-basico" role="tabpanel" aria-labelledby="pills-basico-tab">
                        <div class="card">
                           <div class="card-body pb-0">
                              <div class="row m-0">
                                 <div class="col-md-6 px-1 mb-3">
                                    <label><i class="far fa-heading color-theme me-1"></i> Título</label>
                                    <input type="text" class="form-control" v-model="postagem.titulo" maxlength="250" />
                                 </div>
                                 <div class="col-md-6 px-1 mb-3">
                                    <label><i class="far fa-download color-theme me-1"></i> Arquivo p/ download</label>
                                    <div class="input-group">
                                       <input v-if="postagem.arquivoDownload != null" type="text" class="form-control" v-model="postagem.arquivoDownload" aria-describedby="removeFile" />
                                       <input v-else type="file" class="form-control" ref="anexoDownload" @change="uploadArquivo('Download')" @drop="uploadArquivo('Download')" />
                                       <span v-if="postagem.arquivoDownload != null" class="input-group-text cursor-pointer" id="removeFile" @click="resetInput('Download')">
                                          <i class="far fa-trash text-red"></i>
                                       </span>
                                    </div>
                                 </div>
                                 <div class="w-max-content px-1 mb-3 align-self-end" v-if="postagem.icone != null">
                                    <div class="results__finnaly">
                                       <label class="d-block"><i class="far fa-start color-theme me-1"></i> Ícone <small>(3x2)</small></label>
                                       <img :src="postagem.icone" class="wpx-85" />
                                    </div>
                                 </div>
                                 <div class="px-1 mb-3 align-self-end" :class="postagem.icone == null ? 'col-sm-6' : 'col'">
                                    <div class="form-group file-area">
                                       <label v-if="postagem.icone == null"><i class="far fa-image color-theme me-1"></i> Ícone <small>(3x2)</small></label>
                                       <input type="file" ref="anexoIcone" accept=".jpg,.jpeg,.png,.webp" @change="uploadArquivo('Ícone')" @drop="uploadArquivo('Ícone')" />
                                       <div class="file-dummy">
                                          <div class="default">Escolher ícone</div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="w-max-content px-1 mb-3 align-self-end" v-if="postagem.banner != null">
                                    <div class="results__finnaly">
                                       <label class="d-block"><i class="far fa-image color-theme me-1"></i> Banner <small>(21x9)</small></label>
                                       <img :src="postagem.banner" class="wpx-200" />
                                    </div>
                                 </div>
                                 <div class="px-1 mb-3 align-self-end" :class="postagem.banner == null ? 'col-sm-6' : 'col'">
                                    <div class="form-group file-area">
                                       <label v-if="postagem.banner == null"><i class="far fa-image color-theme me-1"></i> Banner <small>(21x9)</small></label>
                                       <input type="file" ref="anexoBanner" accept=".jpg,.jpeg,.png,.webp" @change="uploadArquivo('Banner')" @drop="uploadArquivo('Banner')" />
                                       <div class="file-dummy">
                                          <div class="default">Escolher banner</div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label><i class="far fa-quote-right color-theme me-1"></i> Resumo</label>
                                    <JoditEditor :value="postagem.descricao" @attValue="postagem.descricao = $event" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="pills-corpo" role="tabpanel" aria-labelledby="pills-corpo-tab">
                        <div class="card">
                           <div class="card-body">
                              <JoditEditor :value="postagem.conteudo" @attValue="postagem.conteudo = $event" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarPostagem">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import noticia from '@/components/blog/Noticia.vue'
import JoditEditor from '@/components/EditorHTML.vue'

export default {
	name: 'Blog',
	data : function () {
      return {
         pesquisa: {'pagina': 0, 'valor': '', 'retorno': {'resultado': [], 'valor': ''}},
         postagem: {'id': null, 'titulo': '', 'descricao': '', 'banner': null, 'conteudo': '', 'icone': null, 'arquivoDownload': null},
      }
   },
   computed: {
		...mapState({
			urlRest: state => state.urlRest,
			tabs: state => state.tabs
		})
	},
   watch: {
		'$route.params.idTab': function () {
         this.buscarPostagens(0)
      }
	},
   components: {
		noticia, JoditEditor
	},
	methods: {
		buscarPostagens : function (pagina) {
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.pagina = pagina == null ? ++this.pesquisa.pagina : pagina

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/searchNoticias',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.pesquisa

			}).then(response => {
            if (this.pesquisa.pagina == 0) {
               this.pesquisa.retorno.resultado = []
            }

            this.pesquisa.retorno.valor = response.data.valor

            response.data.resultado.forEach(p => {
               this.pesquisa.retorno.resultado.push(p)
            });
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogarAdmin')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      abrirPostagem : function (postagem) {
         if (postagem) {
            let postagemCopia = JSON.parse(JSON.stringify(postagem))

            this.postagem = {
               'id': postagemCopia.id, 
               'titulo': postagemCopia.titulo, 
               'descricao': postagemCopia.descricao, 
               'banner': postagemCopia.banner, 
               'conteudo': postagemCopia.conteudo,
               'arquivoDownload': postagemCopia.arquivoDownload,
               'icone': postagemCopia.icone
            }
         } else {
            this.postagem = {'id': null, 'titulo': '', 'descricao': '', 'banner': null, 'conteudo': '', 'icone': null, 'arquivoDownload': null}
         }

         setTimeout(() => {
            if (this.$refs.anexoDownload) this.$refs.anexoDownload.value = null;
            if (this.$refs.anexoIcone) this.$refs.anexoIcone.value = null;
            if (this.$refs.anexoBanner) this.$refs.anexoBanner.value = null;
            
            $('#modalPostagem').modal('show')
         }, 250);
      },
      resetInput : function (tipo) {
         if (tipo == 'Ícone') {
            this.postagem.icone = null;
            this.$refs.anexoIcone.value = null;

         } else if (tipo == 'Banner') {
            this.postagem.banner = null;
            this.$refs.anexoBanner.value = null;

         } else if (tipo == 'Download') {
            this.postagem.arquivoDownload = null;
            this.$refs.anexoDownload.value = null;
         }
      },
      salvarPostagem : function () {
			if (this.postagem.titulo == null || String(this.postagem.titulo).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Título inválido!'
            })

            return
			} else if (this.postagem.conteudo == null || String(this.postagem.conteudo).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Adicione o conteúdo!'
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
            method: 'post',
            url: this.urlRest +'configuracoesAdmin/saveNoticia',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.postagem

         }).then(() => {
				this.buscarPostagens(0)
				$('#modalPostagem').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: 'Postagem salva!'
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogarAdmin')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Código do produto não encontrado!'
                  });
               } else if (error.response.status == 409) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Produto com página já cadastrada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
      uploadArquivo : function (tipo) {
         let dataForm = new FormData();
         this.$store.dispatch('alternarTelaCarregamento', true)

         if (tipo == 'Ícone') {
            for (let file of this.$refs.anexoIcone.files) {
               dataForm.append('fileAdm', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            }
         } else if (tipo == 'Banner') {
            for (let file of this.$refs.anexoBanner.files) {
               dataForm.append('fileAdm', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            }
         } else if (tipo == 'Download') {
            for (let file of this.$refs.anexoDownload.files) {
               dataForm.append('fileAdm', file, String(file.name).replace(/[^\w\s.]/gi, ''));
            }
         }

         this.$axios({
            method: 'post',
            url: this.urlRest +'configuracoesAdmin/uploadArquivo',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then((response) => {
            if (tipo == 'Ícone') {
               this.postagem.icone = response.data[0].url
            } else if (tipo == 'Banner') {
               this.postagem.banner = response.data[0].url
            } else if (tipo == 'Download') {
               this.postagem.arquivoDownload = response.data[0].url
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
	},
	mounted() {
      this.buscarPostagens(0)
	}
}

</script>